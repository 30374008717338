@import  "../../App.scss";


.conversation-head{
    background-color: #f7f7f7;
    height: 100vh;
    img{
        width: 100%;
    }
    .conversation-left{
        height: 95vh;
        overflow-y: scroll;
        .conversation-card{
            background-color: white;
            border-bottom: 1px solid #f7f7f7;
            padding: 3%;
        }
        .conversation-card:hover{
            cursor: pointer;
            background-color: #7ddb5818;
        }
        .active{
            background-color: #7ddb5818;
            color: black !important;
        }
    }

    .conversation-right{
        background-color: white;
        height: 95vh;
        overflow-y: scroll;
        div{
            padding: 1%;
        }
        .sender{
            background-color: #7ed95781;
            border-radius: 10px;
            padding: 1.5%;
            line-height: 2;
        }
        .receiver{
            background-color: #f7f7f7;
            line-height: 2;
            padding: 1.5%;
            border-radius: 10px;
            
        }
        .convoimage{
            width: 30%;
        }
    }
}