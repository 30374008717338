@import  "../../App.scss";


.plans{
    .plan-cont{
        background-color: #f7f7f7;
        border-radius: 10px;
        padding: 2%;
        margin: 2% !important;
    }
}

.conv-cost{
    width: 70vw;
    height: 80vh;
    overflow-y: scroll;
    // display: block;
    // margin: auto;
    background-color: #7ed95765;
    padding: 5px;
    border-radius: 10px;
    .sender{
        background-color: #45BAFF;
        padding: 2% 1%;
        border-radius: 10px;
        color: white;
    }
    p{
        font-size: .8em;
        color: #a1a1a1;
        padding: 5px;
    }
    .receiver{
        background-color: #f7f7f7;
        padding: 2% 1%;
        border-radius: 10px;
    }
}