@import "../App.scss";

.company-div{
    width: 50%;
    padding: 2%;
    margin: 2% auto;
    /* Created with https://www.css-gradient.com */
background: #7ddb5818;
background: -webkit-linear-gradient(top left, #7ddb5818, #aecfa038);
background: -moz-linear-gradient(top left, #7ddb5818, #aecfa038);
background: linear-gradient(to bottom right, #7ddb5818, #aecfa038);
text-align: center;
img{
    width: 35%;
    border-radius: 100%;
    margin: 2% auto;
    display: block;
}

}

.invoice{
    border: 1px solid grey;
    width: 90%;
    margin: 3% auto;
    padding:2% 2%;

    img{
        width: 100%;
    }
    .total-div{
        border: 1px solid #e0e0e0;
        padding:1% 0;
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .company-div{
        width: 80%;
        padding: 2%;
        margin: 2% auto;
        /* Created with https://www.css-gradient.com */

    img{
        width: 35%;
        border-radius: 100%;
        margin: 2% auto;
        display: block;
    }
    
    }
}

