$primary:"#fafafa";
$secondary:"#fcfcfc";
$tertiary:"#ffff";
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Roboto', sans-serif;

h1,h2,h3,h5{
  font-family: $primary-font
}

.display-mobile{
  display: none;
}
.dashboard-container{
  height: 100vh;
  overflow-y: scroll;
}
::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#7DD957 !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {

  .display-mobile{
    display: block;
  }
  .dashboard-container{
    padding-left: 6%;
  }

}