@import "../../App.scss";

.auth-container{
    padding: 2%;
    text-align: center;
    width: 30%;
    margin: auto;
    img{
        width: 50%;
    }
    h3{
        font-size: 2em;
        font-weight: 400;
    }
    h6{

    }
    .textfield{
        margin: 2% 0;
    }
}

.row-head-cont{
    .heading-img{
        width: 100%;
        padding-top: 14%;
    }
.center-img{
    text-align: center;
    input{
        display: block;
        border: 1px solid #a1a1a1;
        border-radius: 30px;
        padding: 2%;
        width: 50%;
        margin: 5% auto;
    }
    button{
        background-color: #7DD957;
        border: none;
        padding: 2% 5%;
        border-radius: 30px;
    }
}
.rightimg{
    width: 65%;
}
}



@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {

    .row-head-cont{
        .heading-img{
            width: 100%;
            padding-top: 14%;
        }
        .start-div{
            img{
                width: 100%;
            }
        }
    .center-img{
        text-align: center;
        input{
            display: block;
            border: 1px solid #a1a1a1;
            border-radius: 30px;
            padding: 4%;
            width: 100%;
            margin: 5% auto;
        }
        button{
            background-color: #7DD957;
            border: none;
            margin: 5% 0;
            padding: 3% 10%;
            border-radius: 30px;
        }
    }
    .rightimg{
        width: 65%;
        margin-top: 15%;
    }
    }
    


    .auth-container{
        padding: 5% 2%;
        width: 80%;
        margin: auto;
        img{
            width: 30%;
        }
        h3{
            font-size: 2em;
            font-weight: 400;
        }
        h6{
    
        }
        .textfield{
            margin: 4% 0;
        }
    }
    
}
