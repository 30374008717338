@import  "../../App.scss";

.footer{
    .link-cont{
        text-align: center;
        .link{
            margin: 0 2%;
            color: black;
        }
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .footer{
        img{
            width: 30%;
            display: block;
            margin: 5% auto;
        }
        .link-cont{
            text-align: center;
            .link{
                margin: 0 2%;
                color: black;
            }
        }
        .para-cont{
            text-align: center;
            margin: 2% 0;
        }
    }
}